import React, {useContext, useEffect, useState} from 'react'
import { FilePond, registerPlugin } from 'react-filepond'
import AWS from 'aws-sdk';
import 'filepond/dist/filepond.min.css'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

import { env } from "../../configs/EnvironmentConfig"
import axios from "axios";
import {AuthContext} from "../../configs/AuthContext";

AWS.config.update({
    accessKeyId: "AKIATAO37WVFO2AWJOFC",
    secretAccessKey: "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",
    region: "eu-south-1",
});
const s3 = new AWS.S3();

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginFileValidateType,FilePondPluginImagePreview)

function EventUploadManager({  media, file,event,reloadEvent }) {
    const [files, setFiles] = useState([])
    const { user} = useContext(AuthContext)
    const setFilesOnChange = () => {

    }
    function formatDate(date) {
        let day = ('0' + date.getDate()).slice(-2); // Add leading zero
        let month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero-based
        let year = date.getFullYear();

        return `${day}-${month}-${year}`;
    }
    const handleProcessFile = (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
        try {


            if (file) {

                console.log("ale-debug file", file);

                let formattedDate = formatDate(new Date());

                // Configura i parametri per l'upload su S3
                const params = {
                    Bucket: env.S3_BUCKET, // sostituisci con il nome del tuo bucket
                    Key: 'copertina-contest/' + formattedDate + '/' + file.name, // nome del file su S3
                    Body: file,
                    ContentType: file.type
                };

                console.log(params)
                // Carica il file su S3
                s3.upload(params, async (err, data) => {
                    if (err) {
                        console.error('Error uploading file: ', err);
                        error()
                        return;
                    }

                    console.log('Successfully uploaded file.', data);
                    console.log("data location", data.Location)
                    console.log("event", event)
                    await axios.post(env.API_ENDPOINT_SERVER_MAFFLE + "/maffle/snapmemories/modify-event", {
                        "id":event.checkoutSessionId,
                        "type":media==="image"?"fotoProfilo":"videoProfilo",
                        "url":data.Location
                    },{
                        headers: {
                            Authorization: `Bearer ${user.token}`,
                        },
                    })
                    reloadEvent()
                    load()
                });


            } else {
                error()
            }
        }catch (e) {
            error()
        }

    }

    useEffect(() => {
        console.log("file", file)
        if (file === '' || file == null) {
            setFiles([])
        } else {
            setFiles(file)
        }
    }, [file]);

    return (
        <div className="App" style={{ fontSize: '20px' }}>
            <FilePond
                files={files}
                onupdatefiles={setFilesOnChange}
                allowMultiple={false}
                maxFiles={1}
                name="files"
                labelIdle='Drag & Drop i tuoi file o <div class="filepond--label-action">Carica da qui</div>'
                acceptedFileTypes={media === 'video' ? [
                    'video/mp4',
                    'video/quicktime',
                    'video/x-m4v',
                    'video/x-msvideo',
                    'video/x-ms-wmv',
                    'video/x-matroska',
                    'video/webm',
                    'video/ogg',
                    'video/x-flv'
                ] : [
                    'image/jpeg',
                    'image/png',
                    'image/gif',
                    'image/bmp',
                    'image/webp',
                    'image/tiff',
                    'image/x-icon',
                    'image/heif',
                    'image/heic'
                ]}
                instantUpload={false}
                allowProcess={true}
                allowImagePreview={false}
                server={{ process: handleProcessFile }}
            />
        </div>
    )
}

export default EventUploadManager;
