import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import Bluedots from '../../assets/images/blue_dotes.png'
import {isMobile} from "react-device-detect";
import {env} from "../../configs/EnvironmentConfig";

function SelectPlanHome({targetRef}) {

    const [tabMenu, tabActive] = useState({a: true});

    return (
        <>
            <section className="row_am pricing_section white_text" ref={targetRef}>
                <div className="pricing_inner">
                    <div className="container">
                        <div className="dotes_blue">
                            <img src={Bluedots} alt="image"/>
                        </div>
                        <div className="section_title">
                            {/*<h2 style={{marginBottom: "45px"}}>I nostri pacchetti</h2>*/}
                            <h2 style={{marginBottom: "45px"}}>Esplora il nostro pacchetto</h2>
                        </div>
                        {/*<div className="tab-content" id="myTabContent">*/}
                        {/*    <div className={`tab-pane fade ${tabMenu.a && "show active"}`} id="monthly" role="tabpanel"*/}
                        {/*         aria-labelledby="monthly-tab">*/}
                        {/*        <div className="pricing_pannel">*/}
                        {/*            <div className="row">*/}
                        {/*                <div className="col-md-4">*/}
                        {/*                    <div className="pannel_block">*/}
                        {/*                        <div className="heading">*/}
                        {/*                            <h3>Basic</h3>*/}
                        {/*                        </div>*/}
                        {/*                        <div className="pricing">*/}
                        {/*                            <h3>*/}
                        {/*                                €89.99*/}
                        {/*                            </h3>*/}
                        {/*                        </div>*/}
                        {/*                        <ul className="features">*/}
                        {/*                            <li>*/}
                        {/*                                <span className="icon">*/}
                        {/*                                    <i className="icofont-check-circled"/>*/}
                        {/*                                </span>*/}
                        {/*                                <p>Limite di 300 foto e video</p>*/}
                        {/*                            </li>*/}
                        {/*                            <li>*/}
                        {/*                                <span className="icon">*/}
                        {/*                                    <i className="icofont-check-circled"/>*/}
                        {/*                                </span>*/}
                        {/*                                <p>3 mesi di storage</p>*/}
                        {/*                            </li>*/}
                        {/*                            <li>*/}
                        {/*                                <span className="icon">*/}
                        {/*                                    <i className="icofont-close-circled"/>*/}
                        {/*                                </span>*/}
                        {/*                                <p>Unico tema 'Blue'</p>*/}
                        {/*                            </li>*/}
                        {/*                            <li>*/}
                        {/*                                <span className="icon">*/}
                        {/*                                    <i className="icofont-close-circled"/>*/}
                        {/*                                </span>*/}
                        {/*                                <p>Locandine escluse</p>*/}
                        {/*                            </li>*/}
                        {/*                        </ul>*/}
                        {/*                        <div className="btn_block">*/}
                        {/*                            <div*/}

                        {/*                                className="btn puprple_btn ml-0">*/}
                        {/*                                <Link to={'/acquista'} style={{color: "white"}}>*/}
                        {/*                                    Visualizza*/}
                        {/*                                </Link>*/}
                        {/*                            </div>*/}
                        {/*                            <div className="btn_bottom"/>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*                <div className="col-md-4">*/}
                        {/*                    <div className="pannel_block highlited_block">*/}
                        {/*                        <div className="heading">*/}
                        {/*                            <h3>Medium</h3>*/}
                        {/*                            <span className="offer">Raccomandato</span>*/}
                        {/*                        </div>*/}
                        {/*                        <div className="pricing">*/}
                        {/*                            <h3>*/}
                        {/*                                €129.99*/}
                        {/*                            </h3>*/}
                        {/*                        </div>*/}
                        {/*                        <ul className="features">*/}
                        {/*                            <li>*/}
                        {/*                                <span className="icon">*/}
                        {/*                                    <i className="icofont-check-circled"/>*/}
                        {/*                                </span>*/}
                        {/*                                <p>Limite di 600 foto e video</p>*/}
                        {/*                            </li>*/}
                        {/*                            <li>*/}
                        {/*                                <span className="icon">*/}
                        {/*                                    <i className="icofont-check-circled"/>*/}
                        {/*                                </span>*/}
                        {/*                                <p>6 mesi di storage</p>*/}
                        {/*                            </li>*/}
                        {/*                            <li>*/}
                        {/*                                <span className="icon">*/}
                        {/*                                    <i className="icofont-check-circled"/>*/}
                        {/*                                </span>*/}
                        {/*                                <p>Temi personalizzabili</p>*/}
                        {/*                            </li>*/}
                        {/*                            <li>*/}
                        {/*                                <span className="icon">*/}
                        {/*                                    <i className="icofont-check-circled"/>*/}
                        {/*                                </span>*/}
                        {/*                                <p>15 locandine incluse</p>*/}
                        {/*                            </li>*/}
                        {/*                        </ul>*/}
                        {/*                        <div className="btn_block white_btn">*/}
                        {/*                            <div*/}

                        {/*                                className="btn puprple_btn ml-0">*/}
                        {/*                                <Link to={'/acquista'}>*/}
                        {/*                                    Visualizza*/}
                        {/*                                </Link>*/}
                        {/*                            </div>*/}
                        {/*                            <div className="btn_bottom"/>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*                <div className="col-md-4">*/}
                        {/*                    <div className="pannel_block">*/}
                        {/*                        <div className="heading">*/}
                        {/*                            <h3>Premium</h3>*/}
                        {/*                        </div>*/}
                        {/*                        <div className="pricing">*/}
                        {/*                            <h3>*/}
                        {/*                                €159.99*/}
                        {/*                            </h3>*/}
                        {/*                        </div>*/}
                        {/*                        <ul className="features">*/}
                        {/*                            <li>*/}
                        {/*                                <span className="icon">*/}
                        {/*                                    <i className="icofont-check-circled"/>*/}
                        {/*                                </span>*/}
                        {/*                                <p>Foto e video illimitati</p>*/}
                        {/*                            </li>*/}
                        {/*                            <li>*/}
                        {/*                                <span className="icon">*/}
                        {/*                                    <i className="icofont-check-circled"/>*/}
                        {/*                                </span>*/}
                        {/*                                <p>1 anno di storage</p>*/}
                        {/*                            </li>*/}
                        {/*                            <li>*/}
                        {/*                                <span className="icon">*/}
                        {/*                                    <i className="icofont-check-circled"/>*/}
                        {/*                                </span>*/}
                        {/*                                <p>Temi personalizzabili</p>*/}
                        {/*                            </li>*/}
                        {/*                            <li>*/}
                        {/*                                <span className="icon">*/}
                        {/*                                    <i className="icofont-check-circled"/>*/}
                        {/*                                </span>*/}
                        {/*                                <p>30 locandine incluse</p>*/}
                        {/*                            </li>*/}
                        {/*                        </ul>*/}
                        {/*                        <div className="btn_block">*/}
                        {/*                            <div*/}

                        {/*                                className="btn puprple_btn ml-0">*/}
                        {/*                                <Link to={'/acquista'} style={{color: "white"}}>*/}
                        {/*                                    Visualizza*/}
                        {/*                                </Link>*/}
                        {/*                            </div>*/}
                        {/*                            <div className="btn_bottom"/>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <p className="contact_text text-center">*/}
                        {/*        Non sei sicuro di quale scegliere? <br/>*/}
                        {/*        <Link*/}
                        {/*            to="https://wa.me/+393791785539?text=Sarei%20interessato%20all'%20acquisto%20di%20un%20QRcode">Contattaci</Link> per*/}
                        {/*        maggiori informazioni!*/}
                        {/*    </p>*/}
                        {/*</div>*/}
                        <div className="tab-content" id="myTabContent">
                            <div className={`tab-pane fade ${tabMenu.a && "show active"}`} id="monthly" role="tabpanel"
                                 aria-labelledby="monthly-tab">
                                <div className="pricing_pannel">
                                    <div className="row">
                                        <div className="col-md-2">
                                        </div>
                                        <div className="col-md-8">
                                            <div className="pannel_block">
                                                <div className="heading" style={{
                                                    textAlign: "center"
                                                }}>
                                                    <h3>Premium</h3>
                                                    <span className="offer">Offerta speciale</span>
                                                </div>
                                                <div className="pricing"
                                                     style={{
                                                         display: "flex",
                                                         justifyContent: "center",
                                                         flexDirection: isMobile ? "column" : "row",
                                                         alignItems: "baseline"

                                                     }}>
                                                    <h2 style={{
                                                        marginRight: "20px"
                                                    }}>
                                                        <del>
                                                            €99.99
                                                        </del>
                                                    </h2>
                                                    <h3>
                                                        €{env.PIANO['unico'].toFixed(2) / 100}
                                                    </h3>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">

                                                        <ul className="features">

                                                            <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled"/>
                                                        </span>
                                                                <p>Foto e video illimitati</p>
                                                            </li>
                                                            <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled"/>
                                                        </span>
                                                                <p>1 anno di storage</p>
                                                            </li>
                                                            <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled"/>
                                                        </span>
                                                                <p>Classifica per la miglior foto</p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <ul className="features">

                                                            <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled"/>
                                                        </span>
                                                                <p>Emoticons animate</p>
                                                            </li>
                                                            <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled"/>
                                                        </span>
                                                                <p>Modalità griglia</p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div style={{
                                                    textAlign: "center"
                                                }}>
                                                    ..e tanto altro ancora!
                                                </div>

                                                <div className="row" style={{
                                                    marginTop: "30px"
                                                }}>
                                                    <div className="col-lg-3 col-3">
                                                    </div>
                                                    <div className="col-lg-6 col-6"
                                                         style={{
                                                             textAlign: "center"
                                                         }}
                                                    >
                                                        <Link to={'/acquista'} style={{color: "white"}}>
                                                            <div className="btn_block">
                                                                <div className="btn puprple_btn ml-0">
                                                                    Scopri il pacchetto
                                                                </div>
                                                                <div className="btn_bottom"/>
                                                            </div>
                                                        </Link>
                                                    </div>

                                                    <div className="col-lg-3 col-3">
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                        </div>

                                        {/*<div className="col-md-4">*/}
                                        {/*    <div className="pannel_block">*/}
                                        {/*        <div className="heading">*/}
                                        {/*            <h3>Premium</h3>*/}
                                        {/*        </div>*/}
                                        {/*        <div className="pricing">*/}
                                        {/*            <h3>*/}
                                        {/*                €159.99*/}
                                        {/*            </h3>*/}
                                        {/*        </div>*/}
                                        {/*        <ul className="features">*/}
                                        {/*            <li>*/}
                                        {/*                <span className="icon">*/}
                                        {/*                    <i className="icofont-check-circled"/>*/}
                                        {/*                </span>*/}
                                        {/*                <p>Foto e video illimitati</p>*/}
                                        {/*            </li>*/}
                                        {/*            <li>*/}
                                        {/*                <span className="icon">*/}
                                        {/*                    <i className="icofont-check-circled"/>*/}
                                        {/*                </span>*/}
                                        {/*                <p>1 anno di storage</p>*/}
                                        {/*            </li>*/}
                                        {/*            <li>*/}
                                        {/*                <span className="icon">*/}
                                        {/*                    <i className="icofont-check-circled"/>*/}
                                        {/*                </span>*/}
                                        {/*                <p>Temi personalizzabili</p>*/}
                                        {/*            </li>*/}
                                        {/*            <li>*/}
                                        {/*                <span className="icon">*/}
                                        {/*                    <i className="icofont-check-circled"/>*/}
                                        {/*                </span>*/}
                                        {/*                <p>30 locandine incluse</p>*/}
                                        {/*            </li>*/}
                                        {/*        </ul>*/}
                                        {/*        <div className="btn_block">*/}
                                        {/*            <div*/}

                                        {/*                className="btn puprple_btn ml-0">*/}
                                        {/*                <Link to={'/acquista'} style={{color: "white"}}>*/}
                                        {/*                    Visualizza*/}
                                        {/*                </Link>*/}
                                        {/*            </div>*/}
                                        {/*            <div className="btn_bottom"/>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                            <p className="contact_text text-center">
                                Non sei sicuro di quale scegliere? <br/>
                                <Link
                                    to="https://wa.me/+393791785539?text=Sarei%20interessato%20all'%20acquisto%20di%20un%20QRcode">Contattaci</Link> per
                                maggiori informazioni!
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SelectPlanHome;